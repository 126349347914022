import React, {useEffect, useState} from "react";
import {Exercise, ExerciseList} from "../backend/repositories/IExerciseRepositoryV2";
import ExerciseRepositoryFactoryV2 from "../backend/repositories/ExerciseRepositoryFactoryV2";
import {Link} from "react-router-dom";
import ImageInput from "../components/ImageInput";

export default function ManageExercisePrograms() {
    const [title, setTitle] = useState<string>("");
    const [images, setImages] = useState<string[]>([]);
    const [selectedExercises, setSelectedExercises] = useState<string[]>([]);
    const [exercises, setExercises] = useState<Exercise[]>([]);
    const [programs, setPrograms] = useState<ExerciseList[]>([]);

    const fetchExercises = async () => {
        try {
            const repository = ExerciseRepositoryFactoryV2.factory();
            const allExercises = await repository.getAllExercises();
            setExercises(allExercises);
        } catch (error) {
            console.error("Erro ao buscar exercícios:", error);
        }
    };

    const fetchPrograms = async () => {
        try {
            const repository = ExerciseRepositoryFactoryV2.factory();
            const allPrograms = await repository.getAllExerciseLists();
            setPrograms(allPrograms);
        } catch (error) {
            console.error("Erro ao buscar programas:", error);
        }
    };

    useEffect(() => {
        fetchExercises();
        fetchPrograms();
    }, []);

    const handleCheckboxChange = (exerciseId: string) => {
        setSelectedExercises((prev) =>
            prev.includes(exerciseId)
                ? prev.filter((id) => id !== exerciseId)
                : [...prev, exerciseId]
        );
    };

    const handleSubmit = async () => {
        if (!title.trim()) {
            alert("O título do programa é obrigatório.");
            return;
        }
        if (selectedExercises.length === 0) {
            alert("Selecione ao menos um exercício.");
            return;
        }

        const newProgram: ExerciseList = {
            id: generateProgramId(),
            title,
            images,
            exerciseIds: selectedExercises,
        };

        try {
            const repository = ExerciseRepositoryFactoryV2.factory();
            await repository.createExerciseList(newProgram);
            alert("Programa criado com sucesso!");
            setTitle("");
            setSelectedExercises([]);
            fetchPrograms();
        } catch (error) {
            console.error("Erro ao salvar programa:", error);
        }
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-semibold mb-4">Gerenciar Listas de Exercícios</h1>
            <Link to="/">
                <button className="bg-blue-500 text-white py-2 px-4 rounded">Voltar</button>
            </Link>

            <div className="mb-4">
                <label htmlFor="title" className="block text-lg font-medium mb-2">
                    Título da Lista
                </label>
                <input
                    id="title"
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Digite o título da lista"
                    className="w-full p-2 border border-gray-300 rounded"
                />
            </div>
            <div>
                <ImageInput
                    onAddImage={(imageUrl) => {
                        const updatedImages = [...images, imageUrl];
                        setImages(updatedImages);
                    }}
                />
                {images?.length > 0 && (
                    <ul className="list-disc pl-5 mt-2">
                        {images.map((image, idx) => (
                            <li key={idx}>{image}</li>
                        ))}
                    </ul>
                )}
            </div>

            <h2 className="text-lg font-medium mb-4">Selecione Exercícios:</h2>
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {exercises.map((exercise) => (
                    <div
                        key={exercise.id}
                        className="border p-4 rounded shadow flex flex-col justify-between"
                    >
                        <h3 className="font-medium">{exercise.name}</h3>
                        <p>Objetivo: {exercise.objective}</p>
                        {exercise.images.length > 0 && (
                            <div className="mt-2">
                                <h4 className="font-medium">Imagens:</h4>
                                <div className="flex overflow-x-scroll overflow-y-hidden">
                                    {exercise.images.map((image, index) => (
                                        <img key={index} src={image} style={{width: '200px'}}/>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="mt-4">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={selectedExercises.includes(exercise.id)}
                                    onChange={() => handleCheckboxChange(exercise.id)}
                                    className="mr-2"
                                />
                                Adicionar à Lista
                            </label>
                        </div>
                    </div>
                ))}
            </div>

            <button
                onClick={handleSubmit}
                className="mt-6 bg-green-500 text-white py-2 px-4 rounded"
            >
                Salvar Lista de Exercícios
            </button>

            <div className="mt-8">
                <h2 className="text-lg font-medium mb-4">Listas de Exercícios Criadas:</h2>
                {programs.map((program) => (
                    <div
                        key={program.id}
                        className="border p-4 rounded shadow mb-4"
                    >
                        <h3 className="font-medium">{program.title}</h3>
                        <p>Exercícios:</p>
                        <ul className="list-disc pl-5">
                            {program.exerciseIds.map((exerciseId) => {
                                const exercise = exercises.find((ex) => ex.id === exerciseId);
                                return (
                                    <li key={exerciseId}>
                                        {exercise ? exercise.name : "Exercício não encontrado"}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
}

const generateProgramId = () => {
    return `program-${Date.now()}`;
};
