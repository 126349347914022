import React, { useState, useEffect } from "react";
import ImageInput from "../components/ImageInput";
import ExerciseRepositoryFactoryV2 from "../backend/repositories/ExerciseRepositoryFactoryV2";
import {Exercise} from "../backend/repositories/IExerciseRepositoryV2";
import {Link} from "react-router-dom";

export default function ManageExercises() {
    const [exercises, setExercises] = useState<Exercise[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [currentExercise, setCurrentExercise] = useState<Exercise | null>(null);

    const fetchExercises = async () => {
        try {
            const repository = ExerciseRepositoryFactoryV2.factory();
            const allExercises = await repository.getAllExercises();
            setExercises(allExercises);
        } catch (error) {
            console.error("Erro ao buscar exercícios:", error);
        }
    };

    useEffect(() => {
        fetchExercises();
    }, []);

    const handleCreateExercise = () => {
        setIsEditing(true);
        setCurrentExercise({
            id: generateExerciseId(),
            name: "",
            equipment: "",
            objective: "",
            series: 0,
            repetitions: 0,
            instructions: [],
            images: [],
        });
    };

    const handleSaveExercise = async () => {
        if (currentExercise) {
            try {
                const repository = ExerciseRepositoryFactoryV2.factory();
                await repository.createExercise(currentExercise);
                alert("Exercício salvo com sucesso!");
                fetchExercises();
                setIsEditing(false);
                setCurrentExercise(null);
            } catch (error) {
                console.error("Erro ao salvar exercício:", error);
            }
        }
    };

    const handleEditExercise = (exercise: Exercise) => {
        setIsEditing(true);
        setCurrentExercise({ ...exercise });
    };

    const handleDeleteExercise = async (id: string) => {
        try {
            const repository = ExerciseRepositoryFactoryV2.factory();
            await repository.deleteExercise(id);
            alert("Exercício excluído com sucesso!");
            fetchExercises();
        } catch (error) {
            console.error("Erro ao excluir exercício:", error);
        }
    };

    const handleInputChange = (key: keyof Exercise, value: any) => {
        if (currentExercise) {
            setCurrentExercise({ ...currentExercise, [key]: value });
        }
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-semibold mb-4">Gerenciar Exercícios</h1>
            <Link to="/">
                <button className="bg-blue-500 text-white py-2 px-4 rounded">Voltar</button>
            </Link>

            {!isEditing ? (
                <>
                    <button
                        onClick={handleCreateExercise}
                        className="bg-blue-500 text-white py-2 px-4 rounded mb-4 ml-2"
                    >
                        Adicionar Exercício
                    </button>
                    <div>
                        {exercises.map((exercise) => (
                            <div
                                key={exercise.id}
                                className="border p-4 mb-4 rounded shadow"
                            >
                                <h2 className="font-medium text-lg">{exercise.name}</h2>
                                <p>Equipamento: {exercise.equipment}</p>
                                <p>Objetivo: {exercise.objective}</p>
                                <button
                                    onClick={() => handleEditExercise(exercise)}
                                    className="bg-green-500 text-white py-1 px-3 rounded mr-2"
                                >
                                    Editar
                                </button>
                                <button
                                    onClick={() => handleDeleteExercise(exercise.id)}
                                    className="bg-red-500 text-white py-1 px-3 rounded"
                                >
                                    Excluir
                                </button>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <div>
                    <h2 className="text-lg font-medium mb-4">
                        {currentExercise?.id ? "Editar Exercício" : "Adicionar Exercício"}
                    </h2>
                    <input
                        type="text"
                        placeholder="Nome do Exercício"
                        value={currentExercise?.name || ""}
                        onChange={(e) => handleInputChange("name", e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded mb-2"
                    />
                    <input
                        type="text"
                        placeholder="Equipamento"
                        value={currentExercise?.equipment || ""}
                        onChange={(e) => handleInputChange("equipment", e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded mb-2"
                    />
                    <input
                        type="text"
                        placeholder="Objetivo"
                        value={currentExercise?.objective || ""}
                        onChange={(e) => handleInputChange("objective", e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded mb-2"
                    />
                    <input
                        type="number"
                        placeholder="Séries"
                        value={currentExercise?.series || ""}
                        onChange={(e) => handleInputChange("series", Number(e.target.value))}
                        className="w-full p-2 border border-gray-300 rounded mb-2"
                    />
                    <input
                        type="number"
                        placeholder="Repetições"
                        value={currentExercise?.repetitions || ""}
                        onChange={(e) => handleInputChange("repetitions", Number(e.target.value))}
                        className="w-full p-2 border border-gray-300 rounded mb-2"
                    />
                    <textarea
                        placeholder="Instruções"
                        value={(currentExercise?.instructions || []).join("\n")}
                        onChange={(e) =>
                            handleInputChange("instructions", e.target.value.split("\n"))
                        }
                        className="w-full p-2 border border-gray-300 rounded mb-2"
                    />
                    <ImageInput
                        onAddImage={(imageUrl) => {
                            if (currentExercise) {
                                const updatedImages = [...(currentExercise.images || []), imageUrl];
                                handleInputChange("images", updatedImages);
                            }
                        }}
                    />
                    {currentExercise && currentExercise?.images?.length > 0 && (
                        <ul className="list-disc pl-5 mt-2">
                            {currentExercise?.images.map((image, idx) => (
                                <li key={idx}>{image}</li>
                            ))}
                        </ul>
                    )}
                    <button
                        onClick={handleSaveExercise}
                        className="bg-green-500 text-white py-2 px-4 rounded mt-2"
                    >
                        Salvar Exercício
                    </button>
                </div>
            )}
        </div>
    );
}

const generateExerciseId = () => {
    return `exercise-${Date.now()}`;
};
